<template>
    <div style="margin-bottom: 100px;">
        <div class="Header">
            <div class="Header__return" @click="step = 0" v-show="step !== 0">
            <font-awesome-icon icon="chevron-left" style="margin-left: 5px; margin-right:10px;"/>
            <p>Volver</p>
            </div>

            <img class="Header__Icon" src="../../assets/icons/svg/setting.svg" alt="">
            <h1 class="Header__Text">{{switchTitle()}}</h1>
        </div>

        <section class="ConfigItems" v-show="step == 0">
            <div class="ConfigItems__Item" @click="step = 1">
                <div class="ConfigItems__Item__ImgContainer">
                <img  class="ConfiItems__Item__ImgContainer__Img" src="../../assets/icons/svg/denuncias.svg" alt="Tipos de denuncia">
                </div>
                <p class="ConfigItems__Item__Text">Configuración Tipos de Denuncia</p>
            </div>
            <div class="ConfigItems__Item" @click="step = 2">
                <div class="ConfigItems__Item__ImgContainer">
                <img  class="ConfigItems__Item__ImgContainer__Img" src="../../assets/icons/svg/c2c.svg" alt="Configuración Click2Call">
                </div>
                <p class="ConfigItems__Item__Text">Configuración Click2Call</p>
            </div>
            <div class="ConfigItems__Item" @click="step = 3">
                <div class="ConfigItems__Item__ImgContainer">
                <img  class="ConfigItems__Item__ImgContainer__Img" src="../../assets/icons/svg/twitter.svg" alt="Configuración Twitter">
                </div>
                <p class="ConfigItems__Item__Text">Configuración Twitter</p>
            </div>
            <div class="ConfigItems__Item" @click="step = 4">
                <div class="ConfigItems__Item__ImgContainer">
                <img  class="ConfigItems__Item__ImgContainer__Img" src="../../assets/icons/svg/empresa.svg" alt="Configuración Empresa">
                </div>
                <p class="ConfigItems__Item__Text">Configuración Empresa</p>
            </div>
            <div class="ConfigItems__Item" @click="step = 5">
                <div class="ConfigItems__Item__ImgContainer">
                <img  class="ConfigItems__Item__ImgContainer__Img" src="../../assets/icons/svg/news.svg" alt="Configuración Noticias">
                </div>
                <p class="ConfigItems__Item__Text">Configuración Noticias</p>
            </div>
            <div class="ConfigItems__Item" @click="step = 6">
                <div class="ConfigItems__Item__ImgContainer">
                <img  class="ConfigItems__Item__ImgContainer__Img" src="../../assets/icons/svg/news.svg" alt="Configuración Noticias">
                </div>
                <p class="ConfigItems__Item__Text">Configuración Publicidad</p>
            </div>
        </section>


         <div v-if="step == 1">
            <ComplaintTypesVue />
         </div>

        <div v-if="step == 2">
            <Click2callConfig />
        </div>

        <div v-if="step == 3">
            <TwitterConfig />
        </div>

        <div v-if="step == 4">
            <EmpresaConfig />
        </div>
        <div v-if="step == 5">
            <NoticiasConfig />
        </div>
        <div v-if="step == 6">
            <AdvertisementConfig />
        </div>
</div>
</template>

<script>
import ComplaintTypesVue from './ComplaintTypes.vue'
import Click2callConfig from './Click2callConfig.vue'
import TwitterConfig from './TwitterConfig.vue'
import EmpresaConfig from './EmpresaConfig'
import NoticiasConfig from './NoticiasConfig'
import AdvertisementConfig from './AdvertisementConfig'
import { CIcon } from '@coreui/icons-vue';
import { cilList } from '@coreui/icons';

export default {
    components: {
        ComplaintTypesVue,
        Click2callConfig,
        TwitterConfig,
        EmpresaConfig,
        NoticiasConfig,
        CIcon,
        NoticiasConfig,
        AdvertisementConfig
    },
    data () {
        return {
            step: 0,
        }
    },
    methods: {
    switchTitle() {
      switch (this.step) {
        case 0:
          return "Configuraciones";
        case 1:
          return "Configuración Tipos de Denuncia"
        case 2:
          return "Configuración Click2Call"
        case 3:
          return "Configuración Twitter"
        case 4:
          return "Configuración Empresa"
        case 5:
          return "Configuración Noticias"
        case 6:
          return "Configuración Publicidad"
        default:
          return "Configuraciones"
      }
    },
    },
}
</script>

<style lang="scss" scoped>
.Header {
    max-width: 1300px;
    width: 90%;
    display: flex;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 12px;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    &__return {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #6C6C71;
        padding-right: 20px;
        &:hover {
            cursor: pointer;
        }
        & p {
            margin-bottom: 0;
        }
    }
    &__Icon {
        padding-right: 10px;
        padding-left: 10px;
        border-left: 1px solid #E4E4E4;
        border-right: 1px solid #E4E4E4;
    }
    &__Text {
        color: #151F33;
        font-size: 24px;
        margin-left: 20px;
    }
}

.ConfigItems {
    max-width: 1200px;
    padding-top: 60px;
    width: 90%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 60%;
    gap: 28px;
    margin: 0 auto;
    &__Item {
        &:hover {
            cursor: pointer;
        }
        &__ImgContainer {
            background-color: #fff;
            border: 1px solid #E4E4E4;
            border-radius: 35px;
            width: 180px;
            height: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            &__Img {
                width: 100px;
            }
        }
        &__Text {
            text-align: center;
            color: #151F33;
            font-size: 16px;
            width: 135px;
            margin: 0 auto;
            margin-top: 8px;
        }
    }
}

@media (max-width: 768px) {
    .ConfigItems {
        flex-wrap: wrap;
        gap: 20px;
    }

    .Header {
        &__Text {
            font-size: 16px;
        }
    }
}
</style>