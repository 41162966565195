import { render, staticRenderFns } from "./SearchUsers.vue?vue&type=template&id=67b200b1&scoped=true&"
import script from "./SearchUsers.vue?vue&type=script&lang=js&"
export * from "./SearchUsers.vue?vue&type=script&lang=js&"
import style0 from "./SearchUsers.vue?vue&type=style&index=0&id=67b200b1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b200b1",
  null
  
)

export default component.exports