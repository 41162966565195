<template>
<div>
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
    <CRow>
          <CCol sm="12">
            <CInput label="Título" v-model="notification_title"/>
          </CCol>
          <CCol sm="12">
            <CInput label="Mensaje" v-model="notification_body"/>
          </CCol>
          <CCol sm="12" style="margin-top: 24px;margin-bottom:24px;">
            <CInputCheckbox
              :checked.sync="sendToAll"
              label="Enviar a todos los usuarios"
            />
          </CCol>
        

        <CCol sm="12" md="6" v-if="!sendToAll">
          <CInput @keypress="searchLoader = true" class="input-search" style="margin-bottom:0 !important;" label="Buscar Usuarios" v-model="search"/>
          <CListGroup v-if="!searchLoader" style="overflow-y: scroll;height: 186px;">
            <CListGroupItem @click="togglerUsersSelected(user.id)" :color="selectedUsers.includes(user.id) ? 'dark' : null" href="#" v-for="user in optionsUserNotification" :key="user.id">
              <p style="margin-bottom: 0;">{{user?.profile?.name}} {{user?.profile?.lastname}} ({{user?.email}})</p>
            </CListGroupItem>
            <CListGroupItem v-if="noresults">
              <p>No se encontraron resultados</p>
            </CListGroupItem>
          </CListGroup>
          <div style="padding: 12px; height: 186px;" v-else>
            <div class="loader"></div>
          </div>
        </CCol>
        <CCol sm="12" md="6" v-if="!sendToAll">
            <div style="overflow-y: scroll;height: 220px;">
              <div v-for="user in userNotificationList" :key="user?.value" style="display:inline-block; margin: 4px; margin-top:12px;">
                <span class="c-multi-select-tag" style="background-color: #ebedef; padding:0.5rem; border-radius: 5px;" v-if="user" >{{user?.label}} <span style="cursor: pointer;" @click="togglerUsersSelected(user.id)">{{user.email}} X</span></span>
              </div>
            </div>
        </CCol>
    </CRow>
        <CButton
            :disabled="(!sendToAll && !selectedUsers.length) || disablebtn || !notification_title || !notification_body"
            class="mt-4"
            color="success"
            variant="outline"
            square
            @click="!sendToAll ? SendNotificationToUsers() : sendNotificationToAll()"
        >
          Enviar Notificación
        </CButton>
</div>
</template>

<script>
import { sendAdvertisementNotificationToUsers, sendAdvertisementNotification } from '../../api/companyConfig'
import { searchUserBySearch } from '../../api/users'
import _ from 'lodash/lodash'

export default {
    props: ['advertisement_id', 'close'],
    data () {
        return {
            optionsUserNotification: [],
            search: '',
            selectedUser: null,
            selectedUsers: [],
            notification_title: '',
            notification_body: '',
            allSearchResults:[],
            toasts: [],
            searchLoader: false,
            noresults: false,
            toasts: [],
            disablebtn: false,
            sendToAll: false
        }
    },
    computed: {
        userNotificationList() {
        const list = [...this.allSearchResults].map(user => {
            if (this.selectedUsers.includes(user.id)) {
                return user
            }
        })
        return list 
    }
  },
    methods: {
    searchUsers() {
      if (this.search.length > 2) {
        const data = {search: this.search};
      searchUserBySearch(data).then(res => {
        this.optionsUserNotification = res.users
        const allIds = this.allSearchResults.map(user => user.id)

        res.users.forEach(user => {
          if (!allIds.includes(user.id)) {
            this.allSearchResults.push({...user})
          }
        })
        this.searchLoader = false
        this.noresults = !res.users.length ? true : false
      })
      } else {
        this.searchLoader = false
      }
    },
    togglerUsersSelected(id) {
      if (this.selectedUsers.includes(id)) {
        this.selectedUsers = this.selectedUsers.filter(userid => userid !== id)
        this.optionsUserNotification = this.optionsUserNotification.map(user => {
          return {...user, disabled: this.selectedUsers.includes(user.id)}
        })
      } else {
        this.selectedUsers.push(id)
        this.optionsUserNotification = this.optionsUserNotification.map(user => {
          return {...user, disabled: this.selectedUsers.includes(user.id)}
        })
      }
    },
    sendNotificationToAll() {
      this.disablebtn = true
      const data = {advertisement_id: this.advertisement_id}

      sendAdvertisementNotification(data).then(res => {
        this.disablebtn = false
        setTimeout(() => {
            this.close()
        }, 1000);
        this.toasts.push({
          title: 'Notificación enviada',
            })
          this.modalNotification = false;
          this.selectedUser = null
          this.selectedUsers = []
          this.notification_title = "",
          this.notification_body = ""
      }).catch(e => {
        this.disablebtn = false
        console.log(e);
        this.toasts.push({
          title: 'Error',
          message: ''
        })
      })
    },
    SendNotificationToUsers() {
        this.disablebtn = true
    const data = {advertisement_id: this.advertisement_id,
                  user_ids: this.selectedUsers,
                  notification_title: this.notification_title,
                  notification_body: this.notification_body
                  }

      sendAdvertisementNotificationToUsers(data).then(res => {
        this.disablebtn = false
        setTimeout(() => {
            this.close()
        }, 1000);
        this.toasts.push({
          title: 'Notificación enviada',
            })
          this.modalNotification = false;
          this.selectedUser = null
          this.selectedUsers = []
          this.notification_title = "",
          this.notification_body = ""
      }).catch(e => {
        this.disablebtn = false
        console.log(e);
        this.toasts.push({
          title: 'Error',
          message: ''
        })
      })
    }
    },
    watch: {
    search: _.debounce(function (value) {
      this.searchUsers()
    }, 1000)
  },
}
</script>

<style lang="scss" scoped>
.loader {
  width: 50px;
  aspect-ratio: 1;
  display:grid;
  -webkit-mask: conic-gradient(from 15deg,#0000,#000);
  animation: l26 1s infinite steps(12);
}
.loader,
.loader:before,
.loader:after{
  background:
    radial-gradient(closest-side at 50% 12.5%,
     #fcee28 96%,#97440000) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
     #cedf3a 96%,#aa310100) 0 50%/80% 20% repeat-x;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loader:after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {transform:rotate(1turn)}
}
</style>